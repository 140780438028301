<template>
<div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="pe-7s-notebook icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>Vocab books:  Reading | Listening Modules</div>
                </div>
            </div>
        </div>
    </div>
  </div>
    <div class="row">
        <div v-for="(studyGuide, index) in studyGuides" class="col-sm-12 col-md-12 col-lg-6 col-xl-6" :key="index">
            <div class="card mb-3 widget-content" :class="[ studyGuide.bg ]" @click="bookSelection(index,studyGuide.bookName)">
                <div class="widget-content-wrapper text-white">
                    <div class="text-left w-100 row">
                       <div class="col-sm-12 col-md-3 col-lg-4 col-xl-2 text-center"> <i class="fas fa-6x" :class="[ studyGuide.icon ]"> </i></div>
                        <div class="col-sm-12 col-md-9 col-lg-8 col-xl-7 text-left">
                         <h3 class="text-white"> {{ studyGuide.title }}</h3>
                         <p class="text-white famTag">{{ studyGuide.famTag }}</p>
                         <p class="text-white desc">{{ studyGuide.descp }}</p>
                          </div>
                          <div class="d-none d-xl-block col-xl-3 text-center barParDiv" style="margin:auto;">
                            <progress-circle :completed-steps="studyGuide.famSteps" :total-steps="studyGuide.totalSteps"
                            :diameter="100"
                            :circle-color="'#e0e5f8'"
                            :start-color="'#011948'"
                            :stop-color="'#86d4f9'"
                            :circle-width="7"
                            :animation-duration="100"
                            :inner-color="'#283450'"
                            :inner-display="'slot'"
                            :percent-color="'#fff'">
                            <p style="color:white;font-size:10px;">{{ studyGuide.perc }}%</p>
                            </progress-circle>
                            <!-- <apexchart type="pie" width="50" :options="chartOptions" :series="series"></apexchart> -->
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
  <div class="row">
    <div class="col-md-12">
      <div class="tabs-row">
        <ul class="nav nav-tabs">
            <li style="display:none" class="nav-item"><a class="nav-link active nohover" data-toggle="tab" href="#tab-1"></a></li>
            <!-- <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab-2">Reading Mode</a></li> My Vocab Books
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab-3">Listening Mode</a></li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="row" v-if="selectedBookType!=''">
                        <div class="col-12 text-center ">
                          <button class="btn btn-default unfamiliar mr-2 selected" @click="familiar=false">Unfamiliar</button>
                          <button class="btn btn-default familiar unSelected" @click="familiar=true">Familiar</button>
                          <hr/>
                        </div>
                      </div>
  <div class="row" v-if="presentedVocabList!=null">
    <div class="col-12">
      <div class="main-card mb-3" >
                <div class="card">
                  <div class="card-header text-white bg-danger">
                   <h4 class="text-white selectedBook"> {{selectedBook}} </h4>
                    <p>{{selectBookDesc}}</p>
                  </div>
                  <div class="card-body exam_histories">
                    <div class="row d-flex justify-content-around">
                      <div class="col-12 maxHeight" v-if="presentedVocabList.length>0">
                      
                      <b-list-group v-for="(item, index) in presentedVocabList" :key="item.uservocab_id">
                        <b-list-group-item :id="index" :class="{'active': isSelected(item.uservocab_id)}" @click="clickWord(item.vocab_desc,item.uservocab_id)">{{item.vocab_desc}}
                        <span :class="item.word_type" class="knownTag">{{item.word_type}}</span>
                        </b-list-group-item>
                      </b-list-group>
                      </div>
                      <div class="col-12 maxHeight" v-else>
                      <b-list-group >
                        <b-list-group-item >No record found</b-list-group-item>
                      </b-list-group>
                      </div>
                    </div>
                  </div>
                </div>
      </div>
    </div>
  </div>
  <b-modal id="dictionery-Modal" ref="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedWord!=''">
              <app-vocabDict :showSave="saveVocabBtn" :passedText="selectedWord" :wordId="selected" :wordType="(!this.familiar)?'Familiar':'Unfamiliar'" :bookName="selectedBookType"/>
         </b-container>
        </b-modal>
        <div class="page-loader" v-if="!isloaded">
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
      </div> 
</div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import apexchart from 'vue-apexcharts'
import dictTemplate from '../dictionerymode/vocabDictionary'
import { ProgressCircle } from 'vue-progress-circle'
import $ from 'jquery';
export default {
  data () {
    return {
      isModalVisible: false,
      Toast: '',
      isloaded:false,
      saveVocabBtn:false,
      currentVocal:'myVocab',
      vocalList:null,
      userVocab:null,
      presentedVocabList:null,
      selected:0,
      selectedWord:'',
      selectedBook:'',
      selectBookDesc:'',
      familiar:false,
      selectedBookType:'',
       studyGuides: [
        {
          title: 'My Vocab List',
          bg: 'bg-midnight-bloom',
          icon: 'fa-book',
          famTag:'Familiar',
          descp:'Unfamiliar words added to "My Vocab List" while practicing.',
          bookName:'MyVocab',
          totalSteps:100,
          famSteps:0, perc:0
        },
        {
          title: 'WFD Exam Vocab',
          bg: 'bg-arielle-smile',
          icon: 'fa-book',
          famTag:'Familiar ',
          descp:'All vocabulary used in Write From Dictation questions (except the extremely easy ones).',
          bookName:'WFD',
          totalSteps:100,
          famSteps:0, perc:0
        },
        {
          title: 'Listening FIB Exam Vocab',
          bg: 'bg-grow-early',
          icon: 'fa-book',
          famTag:'Familiar ',
          descp:'All vocabulary used in Listening FIB questions (except the extremely easy ones).',
          bookName:'ListeningFIB',
          totalSteps:100,
          famSteps:0, perc:0
        },
        {
          title: 'Reading FIB Basic Vocab',
          bg: 'bg-asteroid',
          icon: 'fa-book',
          famTag:'Familiar ',
          descp:'The most frequently-used basic vocabulary from FIB (Reading) and FIB (Reading & Writing) questions. Suitable for score targets between 30 to 55.',
          bookName:'ReadingFIB',
          totalSteps:100,
          famSteps:0, perc:0
        },
        {
          title: 'Reading FIB Advanced Vocab',
          bg: 'bg-night-fade',
          icon: 'fa-book',
          famTag:'Familiar ',
          descp:'The most frequently-used advanced vocabulary from FIB (Reading) and FIB (Reading & Writing) questions. Suitable for score targets of 56 or above.',
          bookName:'ReadingFIB Adv',
          totalSteps:100,
          famSteps:0, perc:0
        },
        {
          title: 'PTE Basic Vocab',
          bg: 'bg-happy-green',
          icon: 'fa-book',
          famTag:'Familiar ',
          descp:'The basic PTE vocabularies that you must know if you are targeting at a score between 30 - 60. It is recommended for all test takers whose vocabulary size is below 4000 words. After familiarising with this list, your vocabulary size will increase to 4600 words.'
        , bookName:'PTE Basic',
        totalSteps:100,
          famSteps:0, perc:0
        }
        ,
        {
          title: 'PTE Advanced Vocab',
          bg: 'bg-midnight-bloom',
          icon: 'fa-book',
          famTag:'Familiar ',
          descp:'The advanced PTE vocabularies that you need to know if you are targeting at a score between 70 - 85. It is recommended for all test takers whose vocabulary size is below 6000 words. After familiarising with this list, your vocabulary size will increase to 8400 words.'
          ,bookName:'PTE Adv',
          totalSteps:100,
          famSteps:0, perc:0
        }
      ],
       series: [10, 90],
          chartOptions: {
            chart: {
              width: 50,
              type: 'pie',
            },
            labels: ['Familiar', 'UnFamiliar'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
    }
  },
  components: {
    'app-vocabDict':dictTemplate,
    ProgressCircle
  },
  beforeMount(){
    //debugger
  this.fetchUserVocabList(false);
  
  },
  methods: {
    ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData'
        }),
        ...mapGetters({
            GetQuestionPractice:'auth/getPracticeNumber'
        }),
    bookSelection(index,bookName){
        this.selectedBook=this.studyGuides[index].title;
        this.selectBookDesc=this.studyGuides[index].descp;
        this.changeVocabList(bookName);
    },
    changeVocabList(bookName){
      //debugger
      this.selectedBookType=bookName
      if(bookName=='MyVocab'){
        this.presentedVocabList=this.userVocab;
         this.filterList(bookName)
      }
      else{
        if(this.vocalList!=null)
        this.filterList(bookName)
      }
    },
    countAll(){
      for (let index = 0; index < this.studyGuides.length; index++) {
        //debugger
        const element = this.studyGuides[index];
        var array=this.vocalList.filter((item)=>{
          return item.book_name==element.bookName
        });
       let fam= array.filter((item)=>{
          return item.word_type=='Familiar'
        }).length;
        let unFam= array.filter((item)=>{
          return item.word_type=='Unfamiliar'
        }).length;
        this.studyGuides[index].totalSteps=fam+unFam;
        this.studyGuides[index].famSteps=fam;
        this.studyGuides[index].perc=Math.round((fam/(fam+unFam))*100);
        this.studyGuides[index].famTag='Familiar '+fam+' | Unfamiliar '+unFam;
      }
    },
    filterList(bookName){
      //debugger
      this.countAll();
        let fam=(this.familiar)?'Familiar':'Unfamiliar';
        if(bookName=='MyVocab'){
           this.presentedVocabList=this.userVocab.filter((item)=>{
                          return item.book_name==bookName
                      });
            for (let index = 0; index < this.userVocab.length; index++) {
                        const element = this.userVocab[index];
                        this.removeDuplicate(this.presentedVocabList,element.vocab_desc,element.book_name,fam)
                      
                    }
        }
        else{
              this.presentedVocabList= this.vocalList.filter((item)=>{
                          return item.book_name==bookName & item.word_type==fam
                      })
                      for (let index = 0; index < this.userVocab.length; index++) {
                        const element = this.userVocab[index];
                        this.removeDuplicate(this.presentedVocabList,element.vocab_desc,element.book_name,element.word_type)
                      
                    }
        }
          
    },
    removeDuplicate(arr,value,bookName,wordType){
      //debugger
       const index = arr.findIndex(({ vocab_desc, book_name,word_type }) => book_name === bookName && vocab_desc === value && word_type!= wordType);
        if(index >= 0) arr.splice(index, 1);
    },
    changeWordType(value,bookName,wordType){
        
        this.fetchUserVocabList(bookName);

      //   let index = this.presentedVocabList.findIndex(({ vocab_desc, book_name,word_type }) => book_name === bookName && vocab_desc === value);

      //  if(wordType=='Familiar')
      //  {
      //   this.presentedVocabList[index].word_type=wordType;
      //  }
      //  else {
      //   //this.presentedVocabList.splice(index, 1);
      //   const userId=this.$ls.get('user').user_id;
      //   index = this.vocalList.findIndex(({ vocab_desc, book_name,user_id }) => book_name === bookName && vocab_desc === value && user_id==userId);
      //   //debugger;
      //    this.vocalList.splice(index, 1);
      //    this.filterList(bookName)

      //  }
      //   this.removeDuplicate(this.presentedVocabList,value,bookName,wordType)
      //   this.$forceUpdate();
    },
    fetchUserVocabList(bookName){
     // this.options.fill.gradient.stops=[0,10]
     //debugger
      const user_id =this.$ls.get('user').user_id
      const url='overallPractice/getVocabList'
      this.fetchQuestionPractice(url).then(()=>{
        this.isloaded=true;
                const response=this.GetQuestionPractice()
                //console.log(response)
                if(response!=null)
                if(response.success==1){
                    this.vocalList=response.data
                    if(this.vocalList!=null)
                    if(this.vocalList.length>0){
                      this.userVocab= this.vocalList.filter((item)=>{
                          return item.user_id==user_id
                      })
                      if(bookName!=''){
                        this.filterList(bookName)
                      }
                      else this.countAll()
                    }
                   // this.bookSelection(0,'MyVocab');
                }
            })
    },
    isSelected: function (i) {
      return i === this.selected
    },
    clickWord(word, id){
      this.selected=id
      this.selectedWord=word
      this.$refs['dictionery-Modal'].show()
    }
  },
  watch:{
    'familiar':{
       deep:true,
            handler ()  {
              if(this.familiar){
                $('button.familiar').addClass('selected').removeClass('unSelected');
                $('button.unfamiliar').removeClass('selected').addClass('unSelected');
              }
              else{
                $('button.familiar').removeClass('selected').addClass('unSelected');
                $('button.unfamiliar').addClass('selected').removeClass('unSelected');
              }
              this.filterList(this.selectedBookType)
            }
    }
  }
}
</script>
<style scoped>
.list-group-item
{
  text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px!important;
}
.list-group-item.active, .list-group-item:hover{
    background-color: #3263a9!important;
    border-color: #3263a9!important;
    color: #fff;
}
.maxHeight{
  max-height: 60vh;
  overflow: scroll;
}
.tabs-row .nav-tabs
{
  margin: 0 0 30px 0!important;
}
.nohover
{
  cursor:default!important;
}
h3.text-white{
        font-weight: 600;
}
.fas.fa-6x
{
  line-height: 6rem;
    font-size: 5.5em;
}
p.famTag{
font-size: 10px;
    font-weight: 600;
    overflow: hidden;
    max-height: 50px;
    min-height: 25px;
}
p.desc{
   font-size: 10px;
    font-weight: 500;
    overflow: hidden;
    max-height: 50px;
    min-height: 50px;
}
.widget-content, .widget-content div, .widget-content h3, .widget-content p{
  cursor: pointer;
}
.admin-section .app-main__inner .bg-danger{
  height: auto !important;
}
.admin-section .app-main__inner .bg-danger p
{
  color: #ccc!important;
    font-size: 12px;
    padding-top: 8px;
}
.knownTag
{
  /* float: right; */
    right: 20px;
    padding: 2px 10px 2px 10px;
    display: inline-block;
    background: yellow;
    color: #000;
    border-radius: 5px;
    border: 1px solid #ccc;
    position: absolute;
    top: 7px;
}
.Familiar{
   background: green!important;
}
button.unfamiliar{
  background: yellow;
    text-transform: uppercase;
    min-width: 200px;
}
button.familiar{
  background: green;
    text-transform: uppercase;
    min-width: 200px;
}
button.selected{
  opacity: 1;
}
button.unSelected{
  opacity: 0.7;
}

.page-loader {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 position: fixed;
	 top: 0;
	 left: 0;
	 width: 100vw;
	 height: 100vh;
	 background-color: #333;
	 z-index: 999;
}
 .cube {
	 width: 40px;
	 height: 40px;
	 margin-right: 10px;
}
 .cube:nth-child(1) {
	 background-color: #8cc271;
}
 .cube:nth-child(2) {
	 background-color: #69beeb;
}
 .cube:nth-child(3) {
	 background-color: #f5aa39;
}
 .cube:nth-child(4) {
	 background-color: #e9643b;
}
 .cube:first-child {
	 animation: left 1s infinite;
}
 .cube:last-child {
	 animation: right 1s infinite 0.5s;
}

 @keyframes left {
	 40% {
		 transform: translateX(-60px);
	}
	 50% {
		 transform: translateX(0);
	}
}
 @keyframes right {
	 40% {
		 transform: translateX(60px);
	}
	 50% {
		 transform: translateX(0);
	}
}
.barParDiv > .circle-progress-container
{
    display: contents!important;
}
</style>