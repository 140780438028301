<template>
<div class="row">
    <div class="col-12">
        <div class="child" >
            <div class="header">
                {{selectedText}}
                <br/>                
            </div>
            <div class="pronounce">
                {{pronounce}}
                <img src="@/assets/images/audio-player.png" @click="playsound()"/>
            </div>
            <hr />
            <b-row class="text-left mb-2">
                    <div class="col-12 mb-2" >
                       <div class="row heading" >
                        <div class="col-6">Definitions</div>
                        <div class="col-6">Examples</div>
                       </div>
                    </div>
                <div class="col-12" v-for="(record, index) in resultData" :key="index">
                       <div class="row" v-for="(item, i) in record.definitions" :key="i">
                        <div class="col-6 mb-2">[{{record.partOfSpeech}}] {{item.definition}}</div>
                        <div class="col-6"> {{item.example}}</div>
                        <!-- <div class="col-12" v-for="(syn, j) in item.synonyms" :key="j">
                            <span>{{syn}} ,</span>
                        </div> -->
                       </div>
                </div>
            </b-row>
            <hr/>
            <div class="leftbtn">
                <b-button variant="outline-warning" class="mr-2 pl-4 pr-4 " @click="$bvModal.hide('dictionery-Modal')">Close Me</b-button>
            </div>
            <div class="rightbtn">
                <b-button v-if="showSave" variant="outline-success" class="mr-2 pl-4 pr-4" @click="saveVocab()">Save Vocabulary</b-button>
                <b-button v-if="!showSave" variant="outline-success" class="mr-2 pl-4 pr-4" @click="updateWordType()">{{selectedWordType}}</b-button>
            </div>
        </div>
    </div>
</div>
    
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import axios from 'axios'

export default {
    props: {
        passedText: {
                type: String
            },
            wordType: {
                type: String,
                required:false
            },
             wordId: {
                type: Number,
                required:false
            },
            bookName:{
                default:0,
                type:String,
                required:false
            },
        showSave:{
            type:Boolean,
            default:true,
            required:false
        }
    },
    data(){
        return {
            isShowMainModal: true,
            currentUserId:0,
            selectedText:this.passedText,
            selectedWordType:this.wordType,
            selectedWordId:this.wordId,
            selectedBookName:this.bookName,
            resultData:null,
            pronounce:'',
            audio:null
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'speakmodule/getDictionery',
            saveAnswer: 'speakmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'speakmodule/questions',
            answerResponse: 'speakmodule/AnswerResponse'
        }),
        updateWordType(){
            var vm=this
            if(this.selectedWordId>0){
//debugger

                const data={
                    vocab_desc:this.selectedText,
                    word_type:this.selectedWordType,
                    url:'userVocab/uservocabupdate',
                    user_id:this.currentUserId,
                    book_name:this.selectedBookName
                }
                 this.saveAnswer(data).then(()=>{
                     //console.log(this.answerResponse)
                    vm.$alert(this.answerResponse().message);
                    vm.$parent.$parent.$parent.changeWordType(this.selectedText,this.selectedBookName,this.selectedWordType)
                    vm.$bvModal.hide('dictionery-Modal')
                 });
            }
        },
        saveVocab(){
            const userData={
                    user_id:this.currentUserId,
                    vocab_desc:this.selectedText,
                    url:'userVocab/saveVocab'
                }
                //console.log(userData)
                 this.saveAnswer(userData).then(()=>{
                     //console.log(this.answerResponse)
                    this.$alert(this.answerResponse().message)
                 });
        },
        showMain(){
            this.isShowMainModal = true;
        },
        getSelectionText() 
        {
            var vm=this
            axios.get('https://api.dictionaryapi.dev/api/v2/entries/en_US/'+this.selectedText).then((response)=>{
                var result=response.data[0];

                vm.resultData = result.meanings
                vm.pronounce = result.phonetics[0].text
                 vm.audio = result.phonetics[0].audio
                this.playsound()
                vm.isShowMainModal = false;
                //console.log(vm.resultData)
            });
            // this.questionservice('https://api.dictionaryapi.dev/api/v2/entries/en_US/'+this.selectedText).then(()=>{
            //     var getQuestionList = this.getQuestionList();
            //     //console.log(getQuestionList);
                
            //     //console.log(getQuestionList[0].phonetics[0].audio)
            //     vm.resultData = getQuestionList[0].meanings
            //     vm.pronounce = getQuestionList[0].phonetics[0].text
            //     vm.audio = getQuestionList[0].phonetics[0].audio
            //     this.playsound()
            //     vm.isShowMainModal = false;
            //     //console.log(vm.resultData)
            // });
        },
        playsound(){
            var audioElement = document.createElement('audio');
            audioElement.setAttribute('src', this.audio);
            audioElement.play()
        }
    },
    beforeMount(){
        var userdata = this.$ls.get('user')
        this.currentUserId= userdata.user_id
        this.getSelectionText()
    }
}
</script>

<style scoped>
.mainheading
{
    font-size: 14px;
font-weight: 500;
}
    .content{
        position: relative;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
        pointer-events: auto;
    }
    .help{
        font-weight: 400;
        color: #999;
        margin-bottom: 8px;
        font-size: .8rem;
        font-size: 1rem;
    }
    .transcript{
        margin-right: 5px;
margin-bottom: 5px;
display: block;
line-height: 32px;
font-size: 16px;
color: #000;
text-align: left !important;
    }
    h2{
        color:#12d3bf;
        font-size:32px;
    }
    .header{
        text-align: left;
font-size: 32px;
color: #12d3bf;
display: inline-block;
margin-right: 10px;
    }
    .heading{
        margin-bottom: 16px;
        color: #999;
        font-size: 16px;
    }
    p{ 
        text-align: justify;
        line-height: 2;
        word-spacing: 3px;
        
    }
    p.paragraph
    {
cursor: pointer;
    }
    .pronounce{
        text-align: left;
        display: inline-block;
    }
    .horizontal-divider{
        display: block;
        clear: both;
        width: 100%;
        min-width: 100%;
        height: 1px;
        margin: 24px 0;
    }
    img{
        cursor: pointer;
    }
    .leftbtn{
        float: left;
    }
    .rightbtn{
        float: right;
    }
    ::-moz-selection { /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  color: red;
  background: yellow;
}
</style>